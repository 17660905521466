import { getAddressFromLatLng } from "./firebaseUtil";
import { EventData, GridItemPromoterType, GridItemType, GuestListItem, Promoter, Ticket } from "./types";




export function parseDateFromString(x: string): string {
    const newDate = new Date(Date.parse(x));
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // Months start at 0!
    let dd = newDate.getDate();
    let dd2 = ""
    let mm2 = ""

    if (dd < 10) { dd2 = '0' + dd; } else { dd2 = dd.toString() }
    if (mm < 10) { mm2 = '0' + mm; } else { mm2 = mm.toString() }
    return dd2 + '.' + mm2 + '.' + yyyy;
}

export function parseTimeDateFromString(x: string): string {
    const newDate = new Date(Date.parse(x));
    const yyyy = newDate.getFullYear();
    const mm = newDate.getMonth() + 1; // Months start at 0!
    const dd = newDate.getDate();
    const hh = newDate.getHours();
    const mi = newDate.getMinutes();
    const ss = newDate.getSeconds();

    const dd2 = dd < 10 ? '0' + dd : dd.toString();
    const mm2 = mm < 10 ? '0' + mm : mm.toString();
    const hh2 = hh < 10 ? '0' + hh : hh.toString();
    const mi2 = mi < 10 ? '0' + mi : mi.toString();
    const ss2 = ss < 10 ? '0' + ss : ss.toString();

    return `${dd2}.${mm2}.${yyyy} ${hh2}:${mi2}:${ss2}`;
}

export function parseNearEventDateFromString(x: string): string {
    const newDate = new Date(Date.parse(x));
    const today = new Date();

    // Zero out time for accurate comparison
    today.setHours(0, 0, 0, 0);
    newDate.setHours(0, 0, 0, 0);

    const diffTime = newDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // Months start at 0!
    let dd = newDate.getDate();
    let dd2 = "";
    let mm2 = "";

    if (dd < 10) {
        dd2 = '0' + dd;
    } else {
        dd2 = dd.toString();
    }
    if (mm < 10) {
        mm2 = '0' + mm;
    } else {
        mm2 = mm.toString();
    }

    const weekdays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    const weekday = weekdays[newDate.getDay()];

    if (diffDays <= 7) {
        return `${weekday}, ${dd2}.${mm2}.${yyyy}`;
    } else {
        return `${dd2}.${mm2}.${yyyy}`;
    }
}




export function getConvertedRows(guestList: GuestListItem[]) {
    const rows: GridItemType[] = []
    guestList.forEach((x) => {
        const checkInStatus = x.checkedIn;
        const newDate = new Date(Date.parse(x.purchasedDate));
        rows.push({
            id: x.ticketId,
            name: x.name,
            lastName: x.lastName,
            checkedIn: checkInStatus,
            purchasedDate: newDate.toLocaleString(),
            gender: x.gender,
            extra: x.extra ? "+" + x.extra : ""

        })
    })
    return rows;
}

export const validateLoginInputs = (email: string | undefined, password: string | undefined) => {
    if (email && password) {
        return true;
    }
    return false;
}

const getLowestPriceTicket = (tickets: Ticket[] | undefined | null) => {
    if (tickets) {
        let res = 0;
        for (let i = 0; i < tickets.length; i++) {
            const ticket = tickets[i];
            //ignore Guestlist tickets
            if (ticket?.ticketTypeName === "GUESTLIST") { continue }
            //check if number and not string
            const ticketPrice = ticket?.ticketTypePrice;
            if (typeof ticketPrice === "number" && ticketPrice > res) {
                res = ticketPrice;
            }
        }
        if (res > 0) {
            return res.toString();
        }
        return ""
    }

}

function hasGuestListTicket(tickets: Ticket[]) {
    for (let i = 0; i < tickets.length; i++) {
        if (tickets[i].ticketTypeName === 'GUESTLIST') {
            return true;
        }
    }
    return false;
}

function removeGuestListTickets(tickets: Ticket[]) {
    return tickets.filter(ticket => ticket.ticketTypeName !== 'GUESTLIST');
}



export const getTicketArray = (oldTickets: Ticket[] | undefined, newPrice: number) => {
    if (!newPrice) {
        return oldTickets;
    }
    if (oldTickets) {
        const finalTickets: Ticket[] = oldTickets.map((x) => x);
        if (oldTickets.length > 0) {
            for (let index = 0; index < oldTickets.length; index++) {
                const ticket = oldTickets[index];
                if (ticket?.ticketTypeName !== "GUESTLIST") {
                    finalTickets[index] = {
                        ...oldTickets[index],
                        ticketTypePrice: newPrice
                    };
                    return finalTickets;
                }
            }
        }
        finalTickets.push({
            'ticketBuyableUntil': new Date().toISOString(),
            'ticketTypeDescription': 'Abendkasse',
            'ticketTypeName': 'Regular',
            'ticketTypePrice': newPrice,
            'ticketsAvailable': 1000,
            'ticketsSold': 0
        });
        return finalTickets;
    }
    return (
        [{
            'ticketBuyableUntil': new Date().toISOString(),
            'ticketTypeDescription': 'Abendkasse',
            'ticketTypeName': 'Regular',
            'ticketTypePrice': newPrice,
            'ticketsAvailable': 1000,
            'ticketsSold': 0
        }]
    )
}

export const getCreateTickets = (newPrice: number, isGuestListActive: boolean, amount?: number, ticketBuyableUntil?: string) => {
    let tickets: Ticket[] = [];

    if (isGuestListActive && amount && ticketBuyableUntil) {
        tickets.push({
            'ticketBuyableUntil': ticketBuyableUntil,
            'ticketTypeDescription': 'Gästeliste',
            'ticketTypeName': 'GUESTLIST',
            'ticketTypePrice': 0,
            'ticketsAvailable': amount,
            'ticketsSold': 0
        })
    }

    if (newPrice) {
        tickets.push({
            'ticketBuyableUntil': new Date().toISOString(),
            'ticketTypeDescription': 'Abendkasse',
            'ticketTypeName': 'Regular',
            'ticketTypePrice': newPrice,
            'ticketsAvailable': 1000,
            'ticketsSold': 0
        })
    }

    return tickets
}

export async function convertEventDataToLocal(eventData: EventData) {
    //ticket
    const ticketPrice = getLowestPriceTicket(eventData?.tickets);
    //location
    const address = await getAddressFromLatLng(eventData?.eventLocation?.latitude, eventData?.eventLocation?.longitude);
    //start and endtime
    const startTime = new Date(eventData.startTime);
    const endTime = new Date(eventData.endTime);
    return {
        ticketPrice: ticketPrice ?? '',
        endTime: endTime.toISOString() ?? '',
        eventName: eventData?.eventName ?? '',
        eventLocation: address ?? '',
        eventDescription: eventData?.eventDescription ?? '',
        eventVenueName: eventData?.eventVenueName ?? '',
        startTime: startTime.toISOString() ?? '',
        age: eventData?.age ?? '',
        genre: eventData?.genre ?? '',
        eventId: eventData?.eventId ?? '',
        videoUrl: eventData?.videoUrl ?? ''
    }
}

export function getConvertedPromotersRows(promotersList: Promoter[]) {
    const rows: GridItemPromoterType[] = []
    promotersList.forEach((x) => {
        rows.push({
            id: x.uid,
            name: x.name,
            lastName: x.lastName,
            gender: x.gender,
            email: x.email,
            clubId: x.clubId
        })
    })
    return rows;
}

export async function resizeAndCompressImage(file: File): Promise<File> {
    const maxWidth = 500;
    const maxHeight = 500;
    const quality = 0.8;
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const compressedFile = new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() });
                        resolve(compressedFile);
                    } else {
                        reject(new Error('Error compressing image'));
                    }
                }, 'image/jpeg', quality);
            } else {
                reject(new Error('Error creating canvas context'));
            }
        };
        img.onerror = () => {
            reject(new Error('Error loading image'));
        };
        img.src = URL.createObjectURL(file);
    });
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Scroll behavior, set to 'smooth' for smooth scrolling
    });
}

export function getEmailPrefix(email: string): string {
    return email.split("@")[0];
}