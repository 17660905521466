import { Alert, Button, Container, Grid, List, ListItem, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { RouletteWheel } from "react-casino-roulette";
import "react-casino-roulette/dist/index.css"; // Import roulette wheel styles
import { sendRouletteLoserEmail, sendRouletteWinnerEmail } from "../../services/user";
import { getEmailPrefix } from "../../util/utils";
import AdPopup from "./AdPopUp";
import "./styles.css";
const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
const MAX_SPINS = 10;

const getNumberColor = (number) => {
    const num = parseInt(number, 10); // Convert string to number
    return redNumbers.includes(num) ? "red" : "black";
};

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
// List of numbers for American Roulette
const rouletteNumbers: string[] = [
    "0",
    "00",
    "0",
    "00",
    "0",
    ...Array.from({ length: 36 }, (_, i) => (i + 1).toString()),
];

const RouletteGame: React.FC = () => {
    const [userEmail, setUserEmail] = useState<string>("");
    const [recipientEmail, setRecipientEmail] = useState<string>("");
    const [start, setStart] = useState<boolean>(false);
    const [winningBet, setWinningBet] = useState<string | null>(null);
    const [winMessage, setWinMessage] = useState<string>("");
    const [error, setError] = useState<string>("");
    const randomLuckyNumberIndex = Math.floor(Math.random() * (rouletteNumbers.length - 2)) + 2; // Ensure we skip "0" and "00"
    const selectedLuckyNumber = rouletteNumbers[randomLuckyNumberIndex];
    const [luckyNumber, setLuckyNumber] = useState<string>(selectedLuckyNumber);
    const [showResult, setShowResult] = useState<boolean>(false); // Controls when to display the result and message
    const [hideInputs, setHideInputs] = useState<boolean>(false); // Hides inputs after the first spin
    const [spinCount, setSpinCount] = useState<number>(0);
    const [showAd, setShowAd] = useState(false);

    useEffect(() => {
        const storedSpinCount = Cookies.get("spinCount");
        if (storedSpinCount) {
            setSpinCount(parseInt(storedSpinCount, 10) || 0);
        }
    }, []);

    useEffect(() => {
        Cookies.set("spinCount", spinCount.toString(), { expires: 1 }); // Expires in 1 day
    }, [spinCount]);

    // Function to handle the spin
    const handleSpin = () => {
        if (!userEmail || !recipientEmail) {
            setError("Bitte gib beide E-Mail-Adressen ein.");
            return;
        }

        if (userEmail === recipientEmail) {
            setError("Die E-Mail-Adressen dürfen nicht identisch sein.");
            return;
        }

        if (!validateEmail(userEmail) || !validateEmail(recipientEmail)) {
            setError("Bitte gib gültige E-Mail-Adressen ein.");
            return;
        }

        if (spinCount >= MAX_SPINS) {
            setError("Du hast die maximale Anzahl an Spins erreicht.");
            return;
        }

        setError(""); // Fehler zurücksetzen
        setHideInputs(true); // Eingabefelder ausblenden

        // Zufällig eine Gewinnzahl auswählen
        const randomIndex = Math.floor(Math.random() * rouletteNumbers.length);
        const selectedNumber = rouletteNumbers[randomIndex];
        setWinningBet(selectedNumber); // Gewinnzahl setzen

        setStart(true); // Animation starten
        setShowResult(false); // Ergebnis erst nach dem Spin anzeigen
    };

    const handleSpinningEnd = () => {
        setStart(false); // Allow the button to be clicked again
        setSpinCount(spinCount + 1); // Increment the spin count
        setShowResult(true); // Show the result and message after spinning ends
        const adShown = Cookies.get("adShown");

        // Determine the outcome after the spin
        if (winningBet === "0" || winningBet === "00") {
            setWinMessage(`Pech gehabt! Eine E-Mail wurde erfolgreich an deine Ex-Freundin (${recipientEmail}) geschickt.`);
            sendRouletteLoserEmail(recipientEmail, getEmailPrefix(userEmail));
        } else if (winningBet === luckyNumber) {
            setWinMessage("Glückwunsch! Du hast freien Club-Eintritt gewonnen und erhältst ihn per E-Mail.");
            sendRouletteWinnerEmail(userEmail);
        } else {
            setWinMessage("Nichts passiert. Versuche es erneut!");
        }
        if (!adShown && Math.random() < 0.3) {
            setShowAd(true);
            Cookies.set("adShown", "true", { expires: 30 / 1440 }); //expire in 30min
        }
    };
    const handleRewardSpins = () => {
        setSpinCount(spinCount - 5);
    };

    return (
        <Container maxWidth="md" style={{ marginTop: "50px", marginBottom: "50px" }}>
            <Typography
                variant="h3"
                gutterBottom
                sx={{
                    fontWeight: '900',
                    textTransform: 'uppercase',
                    background: 'linear-gradient(90deg, #d63031, #fdcb6e)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    letterSpacing: 2
                }}
            >
                💔 Roulette: Ex oder Club?
            </Typography>
            <Typography variant="h6" gutterBottom>
                Gib die <strong>E-Mail</strong> deiner <strong>Ex-Freundin</strong> ein, drehe das <strong>Roulette</strong> und teste dein <strong>Glück</strong>!
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="h6">
                        <strong>0 oder 00?</strong> Du <span style={{ color: '#e74c3c', fontWeight: 'bold' }}>verlierst</span> – sie bekommt eine E-Mail mit <strong>„Ich vermisse dich“</strong>
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="h6">
                        <strong>Glückszahl?</strong> Du <span style={{ color: '#27ae60', fontWeight: 'bold' }}>gewinnst</span> freien Club-Eintritt (an deine E-Mail)
                    </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="h6">
                        <strong>Max. {MAX_SPINS} Spins pro Tag</strong>
                    </Typography>
                </ListItem>
            </List>

            <Grid container spacing={4}>
                {/* Left Column: Form */}
                <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2} className="center-mobile">
                        {/* Typography Section */}
                        <Grid item>
                            <Typography variant="h5" style={{ marginTop: "20px" }}>
                                Spins verfügbar: <strong>{MAX_SPINS - spinCount}</strong>
                            </Typography>

                            {luckyNumber && (
                                <Typography variant="h5" style={{ marginTop: "20px", display: "inline-block" }}>
                                    Gewinnerzahl:
                                    <span
                                        style={{
                                            color: getNumberColor(luckyNumber),
                                            backgroundColor: "white",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            marginLeft: "10px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {luckyNumber}
                                    </span>
                                </Typography>
                            )}
                        </Grid>
                        {hideInputs && (
                            <Grid item>
                                <Typography variant="h5" style={{ display: "inline-block" }}>
                                    Result:
                                    <span
                                        style={{
                                            color: showResult ? getNumberColor(winningBet) : "inherit",
                                            backgroundColor: showResult ? "white" : "transparent",
                                            padding: showResult ? "5px 10px" : "0",
                                            borderRadius: "5px",
                                            marginLeft: "10px",
                                            fontWeight: showResult ? "bold" : "normal"
                                        }}
                                    >
                                        {showResult ? winningBet : ""}
                                    </span>
                                </Typography>
                            </Grid>
                        )}

                        {/* Inputs Section */}
                        {!hideInputs && (
                            <Grid item>
                                <TextField
                                    label="Deine Email"
                                    fullWidth
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    margin="normal"
                                />
                                <TextField
                                    label="😈 Ex-Freundin Email"
                                    fullWidth
                                    value={recipientEmail}
                                    onChange={(e) => setRecipientEmail(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                        )}

                        {/* Button Section */}
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSpin}
                                disabled={start || spinCount >= MAX_SPINS}
                                style={{
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                    padding: "12px 24px",
                                    borderRadius: "8px",
                                    background: start || spinCount >= MAX_SPINS ? "gray" : "linear-gradient(45deg, #ff1744, #ff8f00)",
                                    color: start || spinCount >= MAX_SPINS ? "#bbb" : "white",
                                    boxShadow: start || spinCount >= MAX_SPINS ? "none" : "0px 4px 10px rgba(255, 23, 68, 0.5)",
                                    transition: "transform 0.2s ease, box-shadow 0.2s ease",
                                    cursor: start || spinCount >= MAX_SPINS ? "not-allowed" : "pointer"
                                }}
                                onMouseOver={(e) => {
                                    if (!(start || spinCount >= MAX_SPINS)) e.currentTarget.style.transform = "scale(1.05)";
                                }}
                                onMouseOut={(e) => {
                                    if (!(start || spinCount >= MAX_SPINS)) e.currentTarget.style.transform = "scale(1)";
                                }}
                            >
                                🎰 Spin the Wheel 🎲
                            </Button>
                        </Grid>
                    </Grid>
                    {showResult && winMessage && (
                        <Alert
                            severity={winningBet === "0" || winningBet === "00" ? "error" : winningBet === luckyNumber ? "success" : "info"}
                            style={{
                                marginTop: "20px",
                                fontSize: "18px",
                                fontWeight: "bold",
                                padding: "15px 20px",
                                borderRadius: "10px",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                    winningBet === "0" || winningBet === "00"
                                        ? "linear-gradient(45deg, #ff1744, #d50000)" // Red for loss
                                        : winningBet === luckyNumber
                                            ? "linear-gradient(45deg, #4caf50, #2e7d32)" // Green for win
                                            : "linear-gradient(45deg, #9e9e9e, #616161)", // Neutral gray
                                color: "white",
                            }}
                            icon={winningBet === "0" || winningBet === "00" ? "😈" : winningBet === luckyNumber ? "🔥" : "😐"}
                        >
                            {winMessage}
                        </Alert>
                    )}
                    {error && (
                        <Alert
                            severity="error"
                            sx={{
                                mt: 2,
                                fontSize: "16px",
                                fontWeight: "bold",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 10px rgba(255, 0, 0, 0.3)",
                                background: "linear-gradient(45deg, #ff1744, #d50000)",
                                color: "white",
                            }}
                        >
                            {error}
                        </Alert>
                    )}
                </Grid>

                {/* Right Column: Roulette Wheel */}
                <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center" }}>
                    <RouletteWheel
                        start={start}
                        winningBet={winningBet ?? "-1"}
                        onSpinningEnd={handleSpinningEnd}
                    />
                </Grid>
            </Grid>
            <AdPopup open={showAd} onClose={() => setShowAd(false)} onReward={handleRewardSpins} />
        </Container>
    );
};

export default RouletteGame;
