import { Autocomplete, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputFileUpload from '../../../components/events/InputFileUpload';
import ScrollableCheckboxList from '../../../components/events/ScrollableCheckBoxList';
import InfoTooltip from '../../../components/general/InfoTooltip';
import LoadingView from '../../../components/general/LoadingView';
import TimerAlert from '../../../components/general/TimerAlert';
import { selectUser } from '../../../redux/slices/authSlice';
import { fetchClubs, selectClubList } from '../../../redux/slices/clubsSlice';
import { selectCreateEventStatus, setEventData, uploadEvent } from '../../../redux/slices/createEventSlice';
import { fetchPromoters, selectPromoters, selectPromotersStatus } from '../../../redux/slices/promotersSlice';
import { AppDispatch } from '../../../redux/store/store';
import { getGeoCodeFromAdress } from '../../../util/firebaseUtil';
import { getCreateTickets, scrollToTop } from '../../../util/utils';

export default function CreateEvent() {
    const dispatch: AppDispatch = useDispatch();
    const clubId = useSelector(selectUser)?.clubId;
    const clubPromoters = useSelector(selectPromoters);
    const clubList = useSelector(selectClubList);
    const reqStatus = useSelector(selectCreateEventStatus);
    const promoterReqStatus = useSelector(selectPromotersStatus);
    const user = useSelector(selectUser);
    const isMaster = user?.userType == "MASTER";
    const [guestListActive, setGuestListActive] = useState(() => {
        if (isMaster) {
            return false;
        } else {
            return true;
        }
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [validationErrorMsg, setValidationErrorMsg] = useState("");
    const [masterClubId, setMasterClubId] = useState("");
    const [guestListAmount, setGuestListAmount] = useState(100);
    const [promoterProvision, setPromoterProvision] = useState('');
    const [isPublicPromoteChecked, setIsPublicPromoteChecked] = useState(false);
    const [ticketBuyableUntil, setTicketBuyableUntil] = useState("");
    const [checked, setChecked] = useState<string[]>([]);
    const now = new Date();
    const todayAt10PM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 0, 0);
    const tomorrowAt4AM = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 4, 0, 0);


    const initialEventData = {
        ticketPrice: '',
        endTime: tomorrowAt4AM.toISOString(),
        eventName: '',
        eventLocation: '',
        eventDescription: '',
        eventVenueName: '',
        startTime: todayAt10PM.toISOString(),
        age: '',
        genre: '',
        videoUrl: ''
    };
    const [localEventData, setLocalEventData] = useState(initialEventData);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLocalEventData({ ...localEventData, [name]: value });
    };

    const handleDateTimeChange = (date: Dayjs | null, fieldName: string) => {
        if (!date) return;

        const dateString = date.toISOString();
        let newEventData = { ...localEventData, [fieldName]: dateString };

        if (fieldName === 'startTime') {
            // Set endTime to one day after startTime at 4 AM
            const endTime = date.add(1, 'day').hour(4).minute(0).second(0);
            newEventData = {
                ...newEventData,
                endTime: endTime.toISOString(),
            };
        }

        setLocalEventData(newEventData);
    };

    async function handleSubmit() {
        setValidationErrorMsg("");
        if (await validateInputsAndShowError()) {
            const { lat, lng } = await getGeoCodeFromAdress(localEventData.eventLocation);
            if (lat && lng) {
                dispatch(uploadEvent({
                    eventData: {
                        ...localEventData,
                        clubId: masterClubId ? masterClubId : clubId ?? "",
                        eventLocation: { latitude: lat, longitude: lng },
                        tickets: localEventData.ticketPrice || guestListActive ? getCreateTickets(
                            parseInt(localEventData.ticketPrice),
                            guestListActive,
                            guestListAmount,
                            ticketBuyableUntil
                        ) : [],
                        promoters: checked,
                        promoterProvision: guestListActive ? parseInt(promoterProvision) : 0,
                        publicPromote: guestListActive ? isPublicPromoteChecked : false
                    },
                    file: selectedFile
                }));
                dispatch(setEventData(localEventData));
                resetPage();
            } else {
                setValidationErrorMsg("Die angegebene Adresse ist falsch!")
            }
        }
    };

    function resetPage() {
        setLocalEventData(initialEventData)
        setSelectedFile(null);
        setGuestListActive(true);
        setMasterClubId("");
        setGuestListAmount(100);
        scrollToTop();
    }

    async function validateInputsAndShowError() {
        const chain = localEventData.startTime &&
            localEventData.endTime &&
            localEventData.eventName &&
            localEventData.eventDescription &&
            localEventData.eventLocation &&
            selectedFile &&
            (!guestListActive || (promoterProvision !== undefined && promoterProvision !== '')) &&
            (!guestListActive || (ticketBuyableUntil !== undefined && ticketBuyableUntil !== ''));

        if (!chain) {
            const errorList: string[] = [];
            if (!localEventData.startTime) {
                errorList.push("Startzeit");
            }
            if (!localEventData.endTime) {
                errorList.push("Endzeit");
            }
            if (!localEventData.eventName) {
                errorList.push("Event Name");
            }
            if (!localEventData.eventDescription) {
                errorList.push("Beschreibung");
            }
            if (!localEventData.eventLocation) {
                errorList.push("Event Adresse");
            }
            if (!selectedFile) {
                errorList.push("Bilddatei");
            }
            if (guestListActive && (promoterProvision === undefined || promoterProvision === '')) {
                errorList.push("Provison pro CheckIn in EUR");
            }
            if (guestListActive && (ticketBuyableUntil === undefined || ticketBuyableUntil === '')) {
                errorList.push("Gästeliste Gültigkeit");
            }
            if (errorList.length > 0) {
                const errorMessage = `Die folgenden Felder sind nicht ausgefüllt, werden jedoch benötigt: ${errorList.join(', ')}`;
                setValidationErrorMsg(errorMessage);
                console.error(errorMessage);
            }
        }

        return chain;
    }
    useEffect(() => {
        async function fetchData() {
            dispatch(fetchPromoters())
            if (user?.userType === "MASTER") {
                dispatch(fetchClubs())
            }
        }
        fetchData();
    }, [])

    if (reqStatus === "pending" || promoterReqStatus === "pending") {
        return <LoadingView />
    }


    return (
        <Container maxWidth="md" sx={{ marginTop: 10, marginBottom: 10 }}>
            {validationErrorMsg && <Alert severity="error">{validationErrorMsg}</Alert>}
            {reqStatus === "succeeded" && <TimerAlert variant={"success"} children={"Event erfolgreich erstellt. Achtung es dauert bis zu 3h bis dein Event sichtbar ist"} />}
            <Typography variant="h4" gutterBottom>
                Add Event
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputFileUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimePicker
                            label="Start"
                            ampm={false}
                            name="startTime"
                            value={localEventData.startTime ? dayjs(localEventData.startTime) : null}
                            onChange={(date) => handleDateTimeChange(date, 'startTime')}
                        //renderInput={(props) => <TextField {...props} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimePicker
                            label="Ende"
                            ampm={false}
                            name="endTime"
                            value={localEventData.endTime ? dayjs(localEventData.endTime) : null}
                            onChange={(date) => handleDateTimeChange(date, 'endTime')}
                        //renderInput={(props) => <TextField {...props} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required
                            label="Event Name"
                            name="eventName"
                            value={localEventData.eventName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Mindestalter"
                            name="age"
                            value={localEventData.age}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required
                            label="Adresse"
                            name="eventLocation"
                            value={localEventData.eventLocation}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Name des Veranstaltungsortes"
                            name="eventVenueName"
                            value={localEventData.eventVenueName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Genre"
                            name="genre"
                            value={localEventData.genre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Ticketpreis"
                            name="ticketPrice"
                            value={localEventData.ticketPrice}
                            onChange={handleChange}
                        />
                    </Grid>
                    {isMaster && (
                        <Grid item xs={6}>
                            <Autocomplete
                                options={clubList || []}
                                getOptionLabel={(option) => option.name || ''}
                                value={clubList?.find(club => club.clubId === masterClubId) || null}
                                onChange={(event, newValue) => {
                                    setMasterClubId(newValue?.clubId || '');
                                }}
                                renderInput={(params) => <TextField {...params} label="Clubs" variant="outlined" fullWidth />}
                            />
                        </Grid>
                    )}
                    {isMaster && <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="VideoUrl"
                            name="videoUrl"
                            value={localEventData.videoUrl}
                            onChange={handleChange}
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Beschreibung"
                            name="eventDescription"
                            value={localEventData.eventDescription}
                            multiline
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={guestListActive} onChange={() => setGuestListActive(!guestListActive)} />}
                                label="Gästeliste" />
                        </FormGroup>
                    </Grid>
                    {guestListActive && <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Anzahl Gästeliste"
                            name="guestListAmount"
                            value={guestListAmount}
                            onChange={(e) => setGuestListAmount(parseInt(e.target.value))}
                        />
                    </Grid>}
                    {guestListActive && <Grid item xs={6}>
                        <DateTimePicker
                            label="Gültig bis"
                            ampm={false}
                            name="ticketBuyableUntil"
                            value={ticketBuyableUntil ? dayjs(ticketBuyableUntil) : null}
                            onChange={(date) => setTicketBuyableUntil(date?.toISOString() ?? "")}
                        />
                    </Grid>}
                    {guestListActive && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPublicPromoteChecked}
                                        onChange={(e) => setIsPublicPromoteChecked(e.target.checked)}
                                    />
                                }
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Public Joinable
                                        <InfoTooltip title="Dieses Event ist öffentlich zugänglich für alle Promoter.
                                         D.h. alle Promoter können dein Event promoten und werden im Falle eines Check Ins bezahlt." />
                                    </div>
                                }
                            />
                        </Grid>
                    )}
                    {guestListActive && <Grid item xs={12}>
                        <ScrollableCheckboxList
                            clubPromoters={clubPromoters ?? []}
                            setChecked={setChecked}
                            checked={checked} />
                    </Grid>}
                    {guestListActive && (
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                                <TextField
                                    value={promoterProvision}
                                    required
                                    label="Provison pro CheckIn in EUR"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    margin="normal"
                                    onChange={(e) => setPromoterProvision(e.target.value)}
                                />
                                <InfoTooltip title="Dies ist die Provision für den Promoter angegeben in EUR. 
                                    Der Promoter erhält nur Geld wenn ein Gast sich am Abend wirklich auch eincheckt." />
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ background: "#ef5350" }}
                            onClick={resetPage}
                        >
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}