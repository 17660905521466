import isDev from "../../util/checkDev";
import { API_BASE_URL, API_DEV_URL } from "./apiConstants";

export const buildEventsURL = ({ clubId }: { clubId: string }): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/events';
    } else {
        url += API_BASE_URL + 'owners/events';
    }
    if (clubId) {
        url += "?clubId=" + clubId;
    }
    return url;
}

export const buildGuestListURL = ({ eventId: eventId }: { eventId: string }): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/guestlist';
    } else {
        url += API_BASE_URL + 'owners/guestlist';
    }
    if (eventId) {
        url += "?eventId=" + eventId;
    }
    return url;
}

export const buildGuestListFileURL = ({ eventId: eventId }: { eventId: string }): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/guestlist-export';
    } else {
        url += API_BASE_URL + 'owners/guestlist-export';
    }
    if (eventId) {
        url += "?eventId=" + eventId;
    }
    return url;
}

export const buildUserOwnerDataURL = ({ userId: userId }: { userId: string }): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/user';
    } else {
        url += API_BASE_URL + 'owners/user';
    }
    if (userId) {
        url += "?uid=" + userId;
    }
    return url;
}

export const buildALLEventsURL = ({ radius, offset, limit }): string => {
    let url = "";
    const lat = "48.13767692942834";
    const lon = "11.574506065695632"
    if (isDev()) {
        url += API_DEV_URL + "events?";
        if (lat && lon && radius) {
            url += "lat=" + lat.toString() + "&" + "lon=" + lon.toString() + "&"
            url += "radius=" + radius.toString()
        }
    }
    else {
        url += API_BASE_URL + "events?";
        if (lat && lon && radius) {
            url += "lat=" + lat.toString() + "&" + "lon=" + lon.toString() + "&"
            url += "radius=" + radius.toString()
        }
    }
    //offset
    url += "&offset=" + offset;
    if (limit) {
        url += "&limit=" + limit.toString()
    }
    return url;
}

export const buildOwnerCreateEventURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/createEvent';
    } else {
        url += API_BASE_URL + 'owners/createEvent';
    }
    return url;
}

export const buildOwnerEditEventURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/editEvent';
    } else {
        url += API_BASE_URL + 'owners/editEvent';
    }
    return url;
}

export const buildSingleEventURL = (eventId: string): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'events/' + eventId;
    } else {
        url += API_BASE_URL + 'events/' + eventId;
    }
    return url;
}

export const buildDeleteEventURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/deleteEvent';
    } else {
        url += API_BASE_URL + 'owners/deleteEvent';
    }
    return url;
}

export const buildClubPromotersURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'promoters';
    } else {
        url += API_BASE_URL + 'promoters';
    }
    return url;
}

export const buildGuestListCheckInURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + '/tickets/checkIn';
    } else {
        url += API_BASE_URL + '/tickets/checkIn';
    }
    return url;
}

export const buildNearEventsURL = (): string => {
    //hard coded for now
    const limit = 60;
    const radius = 50;
    const offset = 0;
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + "events?";
        if (radius) {
            //muc
            url += "lat=" + "48.17489573" + "&" + "lon=" + "11.58720520" + "&"
            //sb url += "lat=" + "49.23667" + "&" + "lon=" + "6.99563" + "&"
            //url += "lat=" + lat.toString() + "&" + "lon=" + lon.toString() + "&"
            url += "radius=" + radius.toString()
        }
    }
    else {
        url += API_BASE_URL + "events?";
        if (radius) {
            //muc
            url += "lat=" + "48.17489573" + "&" + "lon=" + "11.58720520" + "&"
            url += "radius=" + radius.toString()
        }
    }
    //offset
    url += "&offset=" + offset;
    if (limit) {
        url += "&limit=" + limit.toString()
    }
    return url;
}

export const buildAllClubsURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'clubs';
    } else {
        url += API_BASE_URL + 'clubs';
    }
    return url;
}

export const buildOwnersContactURL = (): string => {
    let url = "";
    if (isDev()) {
        url += API_DEV_URL + 'owners/contact';
    } else {
        url += API_BASE_URL + 'owners/contact';
    }
    return url;
}

export const buildRouletteLoserURL = (): string => {
    const baseUrl = isDev() ? API_DEV_URL : API_BASE_URL;
    return `${baseUrl}user/roulette-loser`;
};

export const buildRouletteWinnerURL = (): string => {
    return isDev() ? `${API_DEV_URL}user/roulette-winner` : `${API_BASE_URL}user/roulette-winner`;
};