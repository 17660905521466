import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

const AdPopup: React.FC<{ open: boolean; onClose: () => void; onReward: () => void }> = ({ open, onClose, onReward }) => {
    const handleDownload = () => {
        window.open("https://apps.apple.com/de/app/baila/id6462697936", "_blank"); // Replace with your actual app link
        onReward(); // Give free spins after download
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", fontSize: "1.8rem", background: "#222", color: "white" }}>
                🎁 Hol dir die App & kassiere **5 GRATIS Spins!**
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: "absolute", right: 10, top: 10, color: "white" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ display: "flex", alignItems: "center", p: 3, m: 3 }}>
                {/* Left: Image */}
                <Box sx={{ flex: 1, justifyItems: "center" }}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/baila-740b8.appspot.com/o/Simulator%20Screenshot%20-%20iPhone%2016%20-%202025-01-22%20at%2015.17.24-portrait%202.png?alt=media&token=8c8d6670-7caf-4ac0-80a1-ae5f8ca056cb"
                        alt="Party Ad"
                        style={{ width: "45%", height: "45%" }}
                    />
                </Box>

                {/* Right: Text & Benefits */}
                <Box sx={{ flex: 1.5, pl: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Vorteile der App:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon><CheckCircleIcon color="success" /></ListItemIcon>
                            <ListItemText primary="Exklusive VIP-Events" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CheckCircleIcon color="success" /></ListItemIcon>
                            <ListItemText primary="Schneller Club-Eintritt ohne Warteschlange" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CheckCircleIcon color="success" /></ListItemIcon>
                            <ListItemText primary="Special Deals & Getränkerabatte" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CheckCircleIcon color="success" /></ListItemIcon>
                            <ListItemText primary="🎰 5 GRATIS Spins nach dem Download!" />
                        </ListItem>
                    </List>
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        px: 4,
                        py: 1.5,
                        background: "linear-gradient(45deg, #ff1744, #ff8f00)",
                        "&:hover": { opacity: 0.8 },
                    }}
                    onClick={handleDownload}
                >
                    📲 App herunterladen & Freispiele sichern!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdPopup;