import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { deleteSingleEvent, editEvent, getSingleEvent } from '../../services/events';
import { EventData } from '../../util/types';
import { RootState } from '../store/store';


// Define the initial state
interface EventsState {
    event: EventData | null;
    fetchStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    uploadStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    deleteStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

const initialState: EventsState = {
    event: null,
    fetchStatus: 'idle',
    uploadStatus: 'idle',
    deleteStatus: 'idle',
    error: null,
};

// Upload EDIT Event
export const uploadEditEvent = createAsyncThunk('editEvent/uploadEditEvent', async ({ eventData, file }: { eventData: EventData, file: File | null | undefined }) => {
    try {
        if (file || eventData.eventImage) {
            const auth = getAuth();
            const token = await auth.currentUser?.getIdToken();
            if (!token) {
                throw new Error("Failed to retrieve Token")
            }
            // Upload event to api
            const res = await editEvent(eventData, file, token);
            return {
                eventId: res?.data?.eventId
            }
        }
    } catch (error) {
        // Handle errors
        throw new Error('Failed to Edit Event');
    }
});

// Fetch Single Event
export const fetchEvent = createAsyncThunk('editEvent/fetchEvent', async ({ eventId }: { eventId: string }) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Fetch single event from your API
        const res = await getSingleEvent(eventId, token);
        // Return the event
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch single event');
    }
});

// Delete Event
export const deleteEvent = createAsyncThunk('editEvent/deleteEvent', async ({ eventId, clubId, callback }: { eventId: string, clubId: string | undefined, callback: () => void }) => {
    try {
        // Delete event
        if (eventId && clubId) {
            const auth = getAuth();
            const token = await auth.currentUser?.getIdToken();
            if (!token) {
                throw new Error("Failed to retrieve Token")
            }
            const res = await deleteSingleEvent(eventId, token);
            callback()
        } else {
            console.error("Wrong eventID")
        }
    } catch (error) {
        // Handle errors
        throw new Error('Failed to Edit Event');
    }
});

// Create a slice for events
const editEventSlice = createSlice({
    name: 'editEvent',
    initialState,
    reducers: {
        setEventData(state, action) {
            state.event = action.payload;
        },
        resetUploadReqStatus(state) {
            state.uploadStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadEditEvent.pending, (state) => {
                state.uploadStatus = 'pending';
                state.error = null;
            })
            .addCase(uploadEditEvent.fulfilled, (state, action) => {
                state.uploadStatus = 'succeeded';
                state = initialState;
            })
            .addCase(uploadEditEvent.rejected, (state, action) => {
                state.uploadStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchEvent.pending, (state) => {
                state.fetchStatus = 'pending';
                state.error = null;
            })
            .addCase(fetchEvent.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                state.event = action.payload.event;
            })
            .addCase(fetchEvent.rejected, (state, action) => {
                state.fetchStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteEvent.pending, (state) => {
                state.deleteStatus = 'pending';
                state.error = null;
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.error.message;
            })
    },
});

// Exports
export const { setEventData, resetUploadReqStatus } = editEventSlice.actions;
export default editEventSlice.reducer;

// Selectors
export const selectEditEvent = (state: RootState) => state.editEvent.event;
export const selectEditEventFetchStatus = (state: RootState) => state.editEvent.fetchStatus;
export const selectEditEventStatus = (state: RootState) => state.editEvent.uploadStatus;
export const selectDeleteEventStatus = (state: RootState) => state.editEvent.deleteStatus;
export const selectEventError = (state: RootState) => state.editEvent.error;