import { OwnerMessage } from "../util/types";
import { client } from "./api/client";
import { buildOwnersContactURL, buildRouletteLoserURL, buildRouletteWinnerURL, buildUserOwnerDataURL } from "./api/urlBuilder";

export async function getOwnerUserData(userId: string, token: string) {
    const response = await client.get(buildUserOwnerDataURL({ userId: userId }), { token })
    return response.data
}

export async function sendOwnerContactMsg(email: string, name: string, msg: string, token: string) {
    const msgObj: OwnerMessage = {
        email: email,
        message: msg,
        name: name
    }
    const response = await client.post(buildOwnersContactURL(), msgObj, { token });
    return response.data
}

export async function sendRouletteLoserEmail(email: string, text: string) {
    const requestBody = { email: email, username: text };

    const response = await client.post(buildRouletteLoserURL(), requestBody, {});

    return response.data;
}

export async function sendRouletteWinnerEmail(email: string) {
    const requestBody = { email: email };

    const response = await client.post(buildRouletteWinnerURL(), requestBody, {});

    return response.data;
}