import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import OverViewPage from '../pages/dashboard/OverViewPage';
import RedirectPage from '../pages/dashboard/RedirectPage';
import SignIn from '../pages/login/SignIn';
import AmbassadorPage from '../pages/ownersPromo/AmbassadorPage';
import OwnersPromoPage from '../pages/ownersPromo/OwnersPromoPage';
import RouletteGame from '../pages/roulette/RoulettePage';
import PolicyPage from '../pages/welcome/PolicyPage';
import WelcomePage from '../pages/welcome/WelcomePage';
import { FirebaseProvider } from '../services/context/authContext';
import Layout from './Layout';

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    },
});

const AppRouter: React.FC = () => {
    return (
        <Router>
            <ThemeProvider theme={darkTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FirebaseProvider>
                        <Layout>
                            <Routes>
                                <Route path="/" element={<OwnersPromoPage />} />
                                <Route path="/policy" element={<PolicyPage />} />
                                <Route path="/login" element={<SignIn />} />
                                <Route path="/overview" element={<OverViewPage />} />
                                <Route path="/events" element={<RedirectPage />} />
                                <Route path="/welcome" element={<WelcomePage />} />
                                <Route path="/ambassador" element={<AmbassadorPage />} />
                                <Route path="/roulette" element={<RouletteGame />} />
                            </Routes>
                        </Layout>
                    </FirebaseProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </Router>
    );
}

export default AppRouter;
